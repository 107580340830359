import React from "react";

import Slider from "@mui/material/Slider";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import skullLogo from "../../src/assets/skull.svg";
import umbrellaLogo from "../../src/assets/umbrella.svg";
import moneywingLogo from "../../src/assets/moneywing.svg";

//https://iconduck.com/emojis/37445/money-with-wings

class QuestionSet1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      AnnualSalary: this.props.AnnualSalary,
      BirthDate: this.props.BirthDate,
      RetirementAge: this.props.RetirementAge,
      ExpectedLifeSpan: this.props.ExpectedLifeSpan,
      CurrentYear: this.props.CurrentYear,

      dlgOpen: false,
      dlgScroll: "paper",
    };
  }

  handleParamChange = (name, value) => {
    this.setState({ [name]: value });

    switch (name) {
      case "AnnualSalary":
        this.props.Q1handler(
          value,
          this.state.BirthDate,
          this.state.RetirementAge,
          this.state.ExpectedLifeSpan
        );
        break;
      case "BirthDate":
        this.props.Q1handler(
          this.state.AnnualSalary,
          value,
          this.state.RetirementAge,
          this.state.ExpectedLifeSpan
        );
        break;
      case "ExpectedLifeSpan":
        this.props.Q1handler(
          this.state.AnnualSalary,
          value,
          this.state.RetirementAge,
          value
        );
        break;
      default:
        this.props.Q1handler(
          this.state.AnnualSalary,
          this.state.BirthDate,
          this.state.RetirementAge,
          this.state.ExpectedLifeSpan
        );
        break;
    }
  };

  handleParamChangeA = (event) => {
    this.setState({ RetirementAge: event.target.value });
    this.props.Q1handler(
      this.state.AnnualSalary,
      this.state.BirthDate,
      event.target.value,
      this.state.ExpectedLifeSpan
    );
  };

  handleParamChangeB = (event) => {
    this.setState({ ExpectedLifeSpan: event.target.value });
    this.props.Q1handler(
      this.state.AnnualSalary,
      this.state.BirthDate,
      this.state.RetirementAge,
      event.target.value
    );
  };

  render() {
    return (
      <div
        style={{
          textAlign: "left",
        }}
      >
        <div>
          <Accordion expanded={true}>
            <AccordionDetails>
              <h3>About Me</h3>
              <br></br>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="DatePicker2"
                  label={"Birth Year"}
                  views={["year"]}
                  dateFormat="yyyy"
                  onChange={(date) => this.handleParamChange("BirthDate", date)}
                  selected={this.state.BirthDate}
                  defaultValue={this.state.BirthDate}
                  minDate={dayjs("1/1/1950")}
                  maxDate={dayjs("1/1/2005")}
                />
              </LocalizationProvider>
              <br></br>
              <br></br>
              <table
                style={{
                  width: "100%",
                }}
              >
                <tr>
                  <td>
                    When is work over?
                    <br></br>
                    <Slider
                      defaultValue={this.state.RetirementAge}
                      value={this.state.RetirementAge}
                      onChange={this.handleParamChangeA}
                      //min={this.state.CurrentYear - this.state.BirthDate.year() + 1}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      sx={{
                        color: "success.main",
                        "& .MuiSlider-thumb": {
                          background: `url("${umbrellaLogo}")`, // Set your custom icon as the background
                          width: "24px", // Set the width of the icon
                          height: "24px", // Set the height of the icon
                          transform: "translate(-50%, -50%)", // Center the icon over the thumb
                          cursor: "pointer", // Change the cursor to indicate interactivity
                        },
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "right",
                      color: "#2e7d32",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.RetirementAge} years
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td>When is... life over?</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Slider
                      defaultValue={this.state.ExpectedLifeSpan}
                      value={this.state.ExpectedLifeSpan}
                      onChange={this.handleParamChangeB}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      sx={{
                        color: "black",
                        "& .MuiSlider-thumb": {
                          background: `url("${skullLogo}")`, // Set your custom icon as the background
                          width: "24px", // Set the width of the icon
                          height: "24px", // Set the height of the icon
                          transform: "translate(-50%, -50%)", // Center the icon over the thumb
                          cursor: "pointer", // Change the cursor to indicate interactivity
                        },
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "right",
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {this.state.ExpectedLifeSpan} years
                  </td>
                </tr>
                <tr>
                  <td>Current Annual Salary?</td>
                </tr>
                <tr>
                  <td>
                    <Slider
                      value={this.state.AnnualSalary}
                      //valueLabelDisplay="auto"
                      onChange={(event) =>
                        this.handleParamChange(
                          "AnnualSalary",
                          event.target.value
                        )
                      }
                      max={200000}
                      min={32000}
                      step={1000}
                      sx={{
                        "& .MuiSlider-thumb": {
                          background: `url("${moneywingLogo}")`, // Set your custom icon as the background
                          width: "24px", // Set the width of the icon
                          height: "24px", // Set the height of the icon
                          transform: "translate(-50%, -50%)", // Center the icon over the thumb
                          cursor: "pointer", // Change the cursor to indicate interactivity
                        },
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      textAlign: "right",
                      color: "#1976d2",
                      fontWeight: "bold",
                    }}
                  >
                    ${this.state.AnnualSalary}
                  </td>
                </tr>
              </table>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default QuestionSet1;
