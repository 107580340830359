import React from "react";

import dayjs from "dayjs";

import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import StackedBar from "./components/StackedBar";
import AcctLineChart from "./components/AcctLineChart";

import fireLogo from "../src/assets/feuer-fire.gif";

import QuestionSet1 from "./components/QuestionSet1";
import QuestionSet2 from "./components/QuestionSet2";
import QuestionSet3 from "./components/QuestionSet3";
import QuestionSet4 from "./components/QuestionSet4";

class TabView extends React.Component {
  constructor(props) {
    super(props);

    var currentTime = new Date();

    //set default values
    this.state = {
      //DEMOGRAPHIC PARAMETERS and ASSUMPTIONS
      CurrentYear: currentTime.getFullYear(), //right now its 2023
      AnnualSalary: 100000, //as of 2023 this is it
      BirthDate: dayjs("1990-01-01"),
      EmploymentStartDate: dayjs("2015-01-01"),
      RetirementAge: 50,
      UCRPBenefitYear: 2055,

      //RETIREMENT ACCOUNTS
      pensionIncl: false,

      SSIIncl: true,

      rothIncl: false,
      rothBal: 35000, //projected current EOY value
      rothContributions: 6500,

      r403BIncl: false,
      r403BBal: 50000, //projected current EOY value
      r403BContributions: 10000,

      r457BIncl: false,
      r457BBal: 75000, //projected current EOY value
      r457BContributions: 20000,

      //OPTIMISM SLIDERS
      ExpectedSalaryGrowth: 0.05,
      PreRetirementStockGrowth: 0.07,
      PostRetirementStockGrowth: 0.04,
      ExpectedUCRPCOLA: 0.01,
      ExpectedIRAYearlyAdjustments: 0.03, //assume inflation but make it adjustable

      //ADVANCED CONTROLS
      SSIAgeRange: [67, 90],
      ucrpAgeRange: [65, 90],
      rothAgeRange: [50, 64],
      r403BAgeRange: [55, 70],
      r457BAgeRange: [50, 64],

      ExpectedLifeSpan: 90, //changes size of the chart

      AdjustForInflation: false,
      ExpectedInflation: 0.03,

      dlgOpen: false,
      dlgScroll: "paper",

      tabVal: "0",
      tab0Disabled: false,
      tab1Disabled: true,
      tab2Disabled: true,
      tab3Disabled: true,
      tab4Disabled: true,
      tab5Disabled: true,
    };

    /*
      ADVANCED PARAMETERS
        ucrp
          Current Contrbutions
          Lifetime Value
          ucrp Age of Benefit Start
          ucrp Age of Benefit End

        457B+403B (combined choice, custom inputs)
          Current Contributions
          Lifetime Value
          4XXB Age of Benefit Start
          4XXB Age of Benefit End

        Adjust all values for Inflation?
        Expected Inflation
        Expected Sal Delta Yearly (3%)
    */

    //console.log("constructor - STATE",this.state)
    //console.log("constructor - PROPS",this.state)
  }

  Q1handler = (w, x, z, z2) => {
    this.setState({
      AnnualSalary: w,
      BirthDate: x,
      RetirementAge: z,
      ExpectedLifeSpan: z2,
    });
  };

  Q2handler = (a, b, c, d, e, f, g, h, i, j, k, l) => {
    this.setState({
      pensionIncl: a,
      SSIIncl: b,
      rothIncl: c,
      rothBal: d,
      rothContributions: e,
      r403BIncl: f,
      r403BBal: g,
      r403BContributions: h,
      r457BIncl: i,
      r457BBal: j,
      r457BContributions: k,
      EmploymentStartDate: l,
    });
  };

  Q3handler = (a, b, c, d, e) => {
    this.setState({
      ExpectedSalaryGrowth: a,
      PreRetirementStockGrowth: b,
      PostRetirementStockGrowth: c,
      ExpectedUCRPCOLA: d,
      ExpectedIRAYearlyAdjustments: e,
    });
  };

  Q4handler = (a, b, c, d, e, g, h) => {
    this.setState({
      SSIAgeRange: a,
      ucrpAgeRange: b,
      rothAgeRange: c,
      r403BAgeRange: d,
      r457BAgeRange: e,

      AdjustForInflation: g,
      ExpectedInflation: h,
    });
  };

  handleTabChange = (name, value) => {
    this.setState({ tabVal: value });
  };

  next0 = () => {
    this.setState({ tabVal: "1", tab1Disabled: false });
  };
  next1 = () => {
    this.setState({ tabVal: "2", tab2Disabled: false });
  };
  next2 = () => {
    this.setState({ tabVal: "3", tab3Disabled: false });
  };
  next3 = () => {
    this.setState({ tabVal: "4", tab4Disabled: false });
  };
  next4 = () => {
    this.setState({ tabVal: "5", tab5Disabled: false });
  };

  prev1 = () => {
    this.setState({ tabVal: "0" });
  };
  prev2 = () => {
    this.setState({ tabVal: "1" });
  };
  prev3 = () => {
    this.setState({ tabVal: "2" });
  };
  prev4 = () => {
    this.setState({ tabVal: "3" });
  };
  prev5 = () => {
    this.setState({ tabVal: "4" });
  };

  render() {
    return (
      
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={this.state.tabVal}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={this.handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Start" value="0" disabled={this.state.tab0Disabled} />
              <Tab
                label="Demographics"
                value="1"
                disabled={this.state.tab1Disabled}
              />
              <Tab
                label="Accounts"
                value="2"
                disabled={this.state.tab2Disabled}
              />
              <Tab
                label="Optimism/Realism"
                value="3"
                disabled={this.state.tab3Disabled}
              />
              <Tab
                label="Advanced"
                value="4"
                disabled={this.state.tab4Disabled}
              />
              <Tab
                label="Result"
                value="5"
                disabled={this.state.tab5Disabled}
              />
            </TabList>
          </Box>
          <TabPanel value="0">
            <div
              style={{
                textAlign: "left",
              }}
            >
              <img
                style={{ width: "10%", height: "10%" }}
                src={fireLogo}
                alt="fire..."
              />{" "}
              <h1>UCRP FIRE</h1>
              <br></br>
              Tired of work? You may be closer to being finished than you
              thought.
            </div>

            <br></br>

            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ textAlign: "left" }}></td>
                  <td style={{ textAlign: "right" }}>
                    <Button onClick={this.next0}>NEXT</Button>
                  </td>
                </tr>
              </table>
            </div>
          </TabPanel>
          <TabPanel value="1">
            <QuestionSet1
              Q1handler={this.Q1handler}
              AnnualSalary={this.state.AnnualSalary}
              BirthDate={this.state.BirthDate}
              EmploymentStartDate={this.state.EmploymentStartDate}
              RetirementAge={this.state.RetirementAge}
              ExpectedLifeSpan={this.state.ExpectedLifeSpan}
              CurrentYear={this.state.CurrentYear}
            />
            <br></br>
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    <Button onClick={this.prev1}>PREV</Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Button onClick={this.next1}>NEXT</Button>
                  </td>
                </tr>
              </table>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <QuestionSet2
              Q2handler={this.Q2handler}
              pensionIncl={this.state.pensionIncl}
              SSIIncl={this.state.SSIIncl}
              rothIncl={this.state.rothIncl}
              rothBal={this.state.rothBal}
              rothContributions={this.state.rothContributions}
              r403BIncl={this.state.r403BIncl}
              r403BBal={this.state.r403BBal}
              r403BContributions={this.state.r403BContributions}
              r457BIncl={this.state.r457BIncl}
              r457BBal={this.state.r457BBal}
              r457BContributions={this.state.r457BContributions}
              EmploymentStartDate={this.state.EmploymentStartDate}
            />
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    <Button onClick={this.prev2}>PREV</Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Button onClick={this.next2}>NEXT</Button>
                  </td>
                </tr>
              </table>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <QuestionSet3
              Q3handler={this.Q3handler}
              ExpectedSalaryGrowth={this.state.ExpectedSalaryGrowth}
              PreRetirementStockGrowth={this.state.PreRetirementStockGrowth}
              PostRetirementStockGrowth={this.state.PostRetirementStockGrowth}
              ExpectedUCRPCOLA={this.state.ExpectedUCRPCOLA}
              ExpectedIRAYearlyAdjustments={
                this.state.ExpectedIRAYearlyAdjustments
              }
            />
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    <Button onClick={this.prev3}>PREV</Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Button onClick={this.next3}>NEXT</Button>
                  </td>
                </tr>
              </table>
            </div>
          </TabPanel>
          <TabPanel value="4">
            <QuestionSet4
              Q4handler={this.Q4handler}
              rothIncl={this.state.rothIncl}
              r403BIncl={this.state.r403BIncl}
              r457BIncl={this.state.r457BIncl}
              pensionIncl={this.state.pensionIncl}
              SSIIncl={this.state.SSIIncl}
              CurrentYear={this.state.CurrentYear}
              BirthDate={this.state.BirthDate}
              SSIAgeRange={this.state.SSIAgeRange}
              ucrpAgeRange={this.state.ucrpAgeRange}
              rothAgeRange={this.state.rothAgeRange}
              r403BAgeRange={this.state.r403BAgeRange}
              r457BAgeRange={this.state.r457BAgeRange}
              ExpectedLifeSpan={this.state.ExpectedLifeSpan}
              AdjustForInflation={this.state.AdjustForInflation}
              ExpectedInflation={this.state.ExpectedInflation}
            />
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    <Button onClick={this.prev4}>PREV</Button>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Button onClick={this.next4}>NEXT</Button>
                  </td>
                </tr>
              </table>
            </div>
          </TabPanel>
          <TabPanel value="5">
            <div>
              <StackedBar
                data={createDataArray(this.state)}
                rothIncl={this.state.rothIncl}
                r403BIncl={this.state.r403BIncl}
                r457BIncl={this.state.r457BIncl}
                pensionIncl={this.state.pensionIncl}
                SSIIncl={this.state.SSIIncl}
              />
              <AcctLineChart
                data={createDataArray(this.state)}
                rothIncl={this.state.rothIncl}
                r403BIncl={this.state.r403BIncl}
                r457BIncl={this.state.r457BIncl}
              />
            </div>
            <div>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ textAlign: "left" }}>
                    <Button onClick={this.prev5}>PREV</Button>
                  </td>
                  <td style={{ textAlign: "right" }}></td>
                </tr>
              </table>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    );
  }
}

export default TabView;

const createDataArray = (homeState) => {

  try {
    var intBirth = homeState.BirthDate.year();
  let ChartEndYear = intBirth + homeState.ExpectedLifeSpan;
  console.log("recalculating...");

  var foo = [];

  for (var i = 0; i <= ChartEndYear - homeState.CurrentYear; i++) {
    //year
    var year = homeState.CurrentYear + i;

    //sal
    var sal = 0;
    if (i === 0) {
      sal = homeState.AnnualSalary;
    } else if (homeState.CurrentYear + i < homeState.RetirementAge + intBirth) {
      sal = Math.trunc(
        foo[foo.length - 1].sal * (1 + homeState.ExpectedSalaryGrowth)
      );
    } //working
    else {
      sal = 0;
    } //not working

    //ucrp
    var ucrp = 0;
    if (homeState.CurrentYear + i < homeState.UCRPBenefitYear) {
      ucrp = 0;
    } //not yet collecting UCRP Benefit
    else if (homeState.CurrentYear + i === homeState.UCRPBenefitYear) {
      //first year collecting UCRP Benefit
      //find last 3 working years average sal
      var avgSal =
        (foo[homeState.RetirementAge + intBirth - homeState.CurrentYear - 1]
          .sal +
          foo[homeState.RetirementAge + intBirth - homeState.CurrentYear - 2]
            .sal +
          foo[homeState.RetirementAge + intBirth - homeState.CurrentYear - 3]
            .sal) /
        3;

      ucrp =
        (0.011 + 0.0014 * (homeState.UCRPBenefitYear - intBirth - 55)) *
        (homeState.RetirementAge +
          intBirth -
          homeState.EmploymentStartDate.year()) *
        avgSal;
      //TODO: warning message if less than 3 working years are used because retirement is very very close, calculation won't be super accurate and may overestimate base salary
    } else {
      ucrp = Math.trunc(
        foo[foo.length - 1].ucrp * (1 + homeState.ExpectedUCRPCOLA)
      );
    } //collecting UCRP Benefit

    //SSI
    var SSI = 0;
    if (homeState.CurrentYear + i < homeState.BirthDate.year() + 67) {
      //67 is max benefit year
      SSI = 0;
    } //not yet collecting UCRP Benefit
    else if (homeState.CurrentYear + i === homeState.BirthDate.year() + 67) {
      //first year collecting UCRP Benefit - assume max benefit at 67 (no 70 bonus, no early penalties) $45864 in 2023, $88161 in 2057 assuming 2%/yr adjustments upward
      SSI = 48000;
    } else {
      SSI = Math.trunc(
        foo[foo.length - 1].SSI * 1.02 //assume continuing 2% growth
      );
    } //collecting UCRP Benefit

    //roth Balance
    var rothBal = 0;
    if (i === 0) {
      rothBal = homeState.rothBal;
    } //initialize Roth
    else if (
      homeState.CurrentYear + i < homeState.rothAgeRange[0] + intBirth &&
      homeState.CurrentYear + i < homeState.RetirementAge + intBirth
    ) {
      //calculate untouched Roth growth with contributions
      rothBal =
        Math.trunc(
          homeState.rothContributions *
            Math.pow(1 + homeState.ExpectedIRAYearlyAdjustments, i)
        ) +
        Math.trunc(
          foo[foo.length - 1].rothBal * (1 + homeState.PreRetirementStockGrowth)
        );
    } else if (
      homeState.CurrentYear + i < homeState.rothAgeRange[0] + intBirth &&
      homeState.CurrentYear + i >= homeState.RetirementAge + intBirth
    ) {
      //calculate untouched Roth growth
      rothBal = Math.trunc(
        foo[foo.length - 1].rothBal * (1 + homeState.PostRetirementStockGrowth)
      );
    } else if (
      homeState.CurrentYear + i >= homeState.rothAgeRange[0] + intBirth &&
      homeState.CurrentYear + i <= homeState.rothAgeRange[1] + intBirth
    ) {
      //collecting Roth Benefit
      rothBal =
        Math.trunc(
          foo[foo.length - 1].rothBal *
            (1 + homeState.PostRetirementStockGrowth)
        ) -
        -1 *
          Math.trunc(
            PMT(
              homeState.PostRetirementStockGrowth,
              homeState.rothAgeRange[1] +
                intBirth -
                (homeState.rothAgeRange[0] + intBirth) +
                1,
              foo[
                homeState.rothAgeRange[0] + intBirth - homeState.CurrentYear - 1
              ].rothBal,
              0 * -1, //0 to deplete roth down to 0 (this is the target value)
              0
            )
          );
      //TODO: warning message if collecting Roth before this is typically allowed
    } else {
      //target reached, any remaining growth will remain untouched
      rothBal = Math.trunc(
        foo[foo.length - 1].rothBal * (1 + homeState.PostRetirementStockGrowth)
      );
    }

    //roth Expenditure
    var rothExp = 0;
    if (
      homeState.CurrentYear + i >= homeState.rothAgeRange[0] + intBirth &&
      homeState.CurrentYear + i <= homeState.rothAgeRange[1] + intBirth
    ) {
      //collecting Roth Benefit
      rothExp =
        -1 *
        Math.trunc(
          PMT(
            homeState.PostRetirementStockGrowth,
            homeState.rothAgeRange[1] +
              intBirth -
              (homeState.rothAgeRange[0] + intBirth) +
              1,
            foo[
              homeState.rothAgeRange[0] + intBirth - homeState.CurrentYear - 1
            ].rothBal,
            0 * -1, //0 to deplete roth down to 0 (this is the target value)
            0
          )
        );
    } else {
      //collecting UCRP Benefit
      rothExp = 0;
    }

    //r403B Balance
    var r403BBal = 0;
    if (i === 0) {
      r403BBal = homeState.r403BBal;
    } //initialize r403B
    else if (
      homeState.CurrentYear + i < homeState.r403BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i < homeState.RetirementAge + intBirth
    ) {
      //calculate untouched r403B growth with contributions
      r403BBal =
        Math.trunc(
          homeState.r403BContributions *
            Math.pow(1 + homeState.ExpectedIRAYearlyAdjustments, i)
        ) +
        Math.trunc(
          foo[foo.length - 1].r403BBal *
            (1 + homeState.PreRetirementStockGrowth)
        );
    } else if (
      homeState.CurrentYear + i < homeState.r403BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i >= homeState.RetirementAge + intBirth
    ) {
      //calculate untouched r403B growth
      r403BBal = Math.trunc(
        foo[foo.length - 1].r403BBal * (1 + homeState.PostRetirementStockGrowth)
      );
    } else if (
      homeState.CurrentYear + i >= homeState.r403BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i <= homeState.r403BAgeRange[1] + intBirth
    ) {
      //collecting r403B Benefit
      r403BBal =
        Math.trunc(
          foo[foo.length - 1].r403BBal *
            (1 + homeState.PostRetirementStockGrowth)
        ) -
        -1 *
          Math.trunc(
            PMT(
              homeState.PostRetirementStockGrowth,
              homeState.r403BAgeRange[1] +
                intBirth -
                (homeState.r403BAgeRange[0] + intBirth) +
                1,
              foo[
                homeState.r403BAgeRange[0] +
                  intBirth -
                  homeState.CurrentYear -
                  1
              ].r403BBal,
              0 * -1, //0 to deplete roth down to 0 (this is the target value)
              0
            )
          );
      //TODO: warning message if collecting r403B before this is typically allowed
    } else {
      //target reached, any remaining growth will remain untouched
      r403BBal = Math.trunc(
        foo[foo.length - 1].r403BBal * (1 + homeState.PostRetirementStockGrowth)
      );
    }

    //r403B Expenditure
    var r403BExp = 0;
    if (
      homeState.CurrentYear + i >= homeState.r403BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i <= homeState.r403BAgeRange[1] + intBirth
    ) {
      //collecting r403B Benefit
      r403BExp =
        -1 *
        Math.trunc(
          PMT(
            homeState.PostRetirementStockGrowth,
            homeState.r403BAgeRange[1] +
              intBirth -
              (homeState.r403BAgeRange[0] + intBirth) +
              1,
            foo[
              homeState.r403BAgeRange[0] + intBirth - homeState.CurrentYear - 1
            ].r403BBal,
            0 * -1, //0 to deplete roth down to 0 (this is the target value)
            0
          )
        );
    } else {
      //collecting UCRP Benefit
      r403BExp = 0;
    }

    //r457B Balance
    var r457BBal = 0;
    if (i === 0) {
      r457BBal = homeState.r457BBal;
    } //initialize r457B
    else if (
      homeState.CurrentYear + i < homeState.r457BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i < homeState.RetirementAge + intBirth
    ) {
      //calculate untouched r457B growth with contributions
      r457BBal =
        Math.trunc(
          homeState.r457BContributions *
            Math.pow(1 + homeState.ExpectedIRAYearlyAdjustments, i)
        ) +
        Math.trunc(
          foo[foo.length - 1].r457BBal *
            (1 + homeState.PreRetirementStockGrowth)
        );
    } else if (
      homeState.CurrentYear + i < homeState.r457BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i >= homeState.RetirementAge + intBirth
    ) {
      //calculate untouched r457B growth
      r457BBal = Math.trunc(
        foo[foo.length - 1].r457BBal * (1 + homeState.PostRetirementStockGrowth)
      );
    } else if (
      homeState.CurrentYear + i >= homeState.r457BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i <= homeState.r457BAgeRange[1] + intBirth
    ) {
      //collecting r457B Benefit
      r457BBal =
        Math.trunc(
          foo[foo.length - 1].r457BBal *
            (1 + homeState.PostRetirementStockGrowth)
        ) -
        -1 *
          Math.trunc(
            PMT(
              homeState.PostRetirementStockGrowth,
              homeState.r457BAgeRange[1] +
                intBirth -
                (homeState.r457BAgeRange[0] + intBirth) +
                1,
              foo[
                homeState.r457BAgeRange[0] +
                  intBirth -
                  homeState.CurrentYear -
                  1
              ].r457BBal,
              0 * -1, //0 to deplete roth down to 0 (this is the target value)
              0
            )
          );
      //TODO: warning message if collecting r457B before this is typically allowed
    } else {
      //target reached, any remaining growth will remain untouched
      r457BBal = Math.trunc(
        foo[foo.length - 1].r457BBal * (1 + homeState.PostRetirementStockGrowth)
      );
    }

    //r457B Expenditure
    var r457BExp = 0;
    if (
      homeState.CurrentYear + i >= homeState.r457BAgeRange[0] + intBirth &&
      homeState.CurrentYear + i <= homeState.r457BAgeRange[1] + intBirth
    ) {
      //collecting r457B Benefit
      r457BExp =
        -1 *
        Math.trunc(
          PMT(
            homeState.PostRetirementStockGrowth,
            homeState.r457BAgeRange[1] +
              intBirth -
              (homeState.r457BAgeRange[0] + intBirth) +
              1,
            foo[
              homeState.r457BAgeRange[0] + intBirth - homeState.CurrentYear - 1
            ].r457BBal,
            0 * -1,
            0
          )
        );
    } else {
      //collecting UCRP Benefit
      r457BExp = 0;
    }

    foo.push({
      year: year,
      sal: sal,
      ucrp: ucrp,
      SSI: SSI,
      rothBal: rothBal,
      rothExp: rothExp,
      r403BBal: r403BBal,
      r403BExp: r403BExp,
      r457BBal: r457BBal,
      r457BExp: r457BExp,
    });
  }

  //console.log(foo)

  return foo;
  } catch (error) {
    console.error(error);
    // Expected output: ReferenceError: nonExistentFunction is not defined
    // (Note: the exact output may be browser-dependent)
  }

  
};

function PMT(ir, np, pv, fv, type) {
  /*
   * ir   - interest rate per month
   * np   - number of periods (months)
   * pv   - present value
   * fv   - future value
   * type - when the payments are due:
   *        0: end of the period, e.g. end of month (default)
   *        1: beginning of period
   */
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
}
