import React from "react";

import Slider from "@mui/material/Slider";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

class QuestionSet4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pensionIncl: this.props.pensionIncl,
      SSIIncl: this.props.SSIIncl,
      rothIncl: this.props.rothIncl,
      r403BIncl: this.props.r403BIncl,
      r457BIncl: this.props.r457BIncl,
      CurrentYear: this.props.CurrentYear,
      BirthDate: this.props.BirthDate,
      SSIAgeRange: this.props.SSIAgeRange,
      ucrpAgeRange: this.props.ucrpAgeRange,
      rothAgeRange: this.props.rothAgeRange,
      r403BAgeRange: this.props.r403BAgeRange,
      r457BAgeRange: this.props.r457BAgeRange,
      ExpectedLifeSpan: this.props.ExpectedLifeSpan,
      AdjustForInflation: this.props.AdjustForInflation,
      ExpectedInflation: this.props.ExpectedInflation,
    };
  }

  handleChange5 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const minDistance = 5;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        this.setState({
          SSIAgeRange: [clamped, clamped + minDistance],
        });
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        this.setState({
          SSIAgeRange: [clamped - minDistance, clamped],
        });
      }
    } else {
      this.setState({ SSIAgeRange: newValue });
    }

    this.props.Q4handler(
      newValue,
      this.state.ucrpAgeRange,
      this.state.rothAgeRange,
      this.state.r403BAgeRange,
      this.state.r457BAgeRange,
      this.state.AdjustForInflation,
      this.state.ExpectedInflation
    );
  };

  handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const minDistance = 5;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        this.setState({ rothAgeRange: [clamped, clamped + minDistance] });
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        this.setState({ rothAgeRange: [clamped - minDistance, clamped] });
      }
    } else {
      this.setState({ rothAgeRange: newValue });
    }

    this.props.Q4handler(
      this.state.SSIAgeRange,
      this.state.ucrpAgeRange,
      newValue,
      this.state.r403BAgeRange,
      this.state.r457BAgeRange,
      this.state.AdjustForInflation,
      this.state.ExpectedInflation
    );
  };

  handleChange3 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const minDistance = 5;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        this.setState({ r403BAgeRange: [clamped, clamped + minDistance] });
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        this.setState({ r403BAgeRange: [clamped - minDistance, clamped] });
      }
    } else {
      this.setState({ r403BAgeRange: newValue });
    }

    this.props.Q4handler(
      this.state.SSIAgeRange,
      this.state.ucrpAgeRange,
      this.state.rothAgeRange,
      newValue,
      this.state.r457BAgeRange,
      this.state.AdjustForInflation,
      this.state.ExpectedInflation
    );
  };

  handleChange4 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const minDistance = 5;

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        this.setState({ r457BAgeRange: [clamped, clamped + minDistance] });
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        this.setState({ r457BAgeRange: [clamped - minDistance, clamped] });
      }
    } else {
      this.setState({ r457BAgeRange: newValue });
    }

    this.props.Q4handler(
      this.state.SSIAgeRange,
      this.state.ucrpAgeRange,
      this.state.rothAgeRange,
      this.state.r403BAgeRange,
      newValue,
      this.state.AdjustForInflation,
      this.state.ExpectedInflation
    );
  };

  render() {
    return (
      <div
        style={{
          textAlign: "left",
        }}
      >
        <div>
          <Accordion expanded={true}>
            <AccordionDetails>
              <h3>Advanced Strategy</h3>
              Different account types have different rules. You may be
              constrained by withdrawal limitations, required minimum
              distributions, and tax implications. These sliders allow adjusting
              periods for when to withdraw and how aggressively to withdraw the
              funds. Additionally this model assumes you intend to amortize the
              account balances to 0 during the withdrawal period to avoid the
              IRS mandatory distributions. <br></br>
              <br />
              <br />
              <Typography>Social Security Period</Typography>
              <Slider
                id=""
                value={this.state.SSIAgeRange}
                onChange={this.handleChange5}
                valueLabelDisplay="auto"
                disableSwap
                min={this.state.CurrentYear - this.state.BirthDate.year() + 1}
                max={this.state.ExpectedLifeSpan}
                //style={{ width: 300 }}
                disabled={!this.state.SSIIncl}
              />
              <br />
              <Typography>Roth Withdrawal Period</Typography>
              <Slider
                id="slider1"
                value={this.state.rothAgeRange}
                onChange={this.handleChange2}
                valueLabelDisplay="auto"
                disableSwap
                min={this.state.CurrentYear - this.state.BirthDate.year() + 1}
                //style={{ width: 300 }}
                disabled={!this.state.rothIncl}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <br />
              <Typography>403B Withdrawal Period</Typography>
              <Slider
                id="slider2"
                value={this.state.r403BAgeRange}
                onChange={this.handleChange3}
                valueLabelDisplay="auto"
                disableSwap
                min={this.state.CurrentYear - this.state.BirthDate.year() + 1}
                //style={{ width: 300 }}
                disabled={!this.state.r403BIncl}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <br />
              <Typography>457B Withdrawal Period</Typography>
              <Slider
                id="slider3"
                value={this.state.r457BAgeRange}
                onChange={this.handleChange4}
                valueLabelDisplay="auto"
                disableSwap
                min={this.state.CurrentYear - this.state.BirthDate.year() + 1}
                //style={{ width: 300 }}
                disabled={!this.state.r457BIncl}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default QuestionSet4;
