import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import Button from "@mui/material/Button";

import Checkbox from "@mui/material/Checkbox";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Link from "@mui/material/Link";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

class QuestionSet2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pensionIncl: this.props.pensionIncl,
      SSIIncl: this.props.SSIIncl,
      rothIncl: this.props.rothIncl,
      rothBal: this.props.rothBal,
      rothContributions: this.props.rothContributions,
      r403BIncl: this.props.r403BIncl,
      r403BBal: this.props.r403BBal,
      r403BContributions: this.props.r403BContributions,
      r457BIncl: this.props.r457BIncl,
      r457BBal: this.props.r457BBal,
      r457BContributions: this.props.r457BContributions,

      EmploymentStartDate: this.props.EmploymentStartDate,

      dlgOpen: false,
      dlgScroll: "paper",
      dlgTitle: "",
      dlgText: "",
      loremipsum: "",
      dlgUCRP:
        "This is text about how UCRP works. It is a pension plan detailed here: https://ucnet.universityofcalifornia.edu/forms/pdf/retirement-handbook.pdf",
      dlgSSI:
        "This is text about how UCRP works. It is a pension plan detailed here: https://ucnet.universityofcalifornia.edu/forms/pdf/retirement-handbook.pdf",
      dlgRoth:
        "How does a Roth IRA work? <br/> A Roth IRA works by taking after-tax dollars from a qualifying source of earned income. Money contributed to your Roth IRA could come from a job, but could also be a rollover from a Roth 401(k) plan, conversion from an existing traditional IRA or 401(k) plan, a spousal contribution, or other transfer. (More on these options below.)<br><br>Then, you’ll need to choose a broker to open your Roth IRA and select where you want to invest the money. Over a long time horizon, those investments could earn a return.<br><br>That’s where the real benefit of the Roth IRA kicks in: Your investment growth could have been taxed when it was time to withdraw the money, but because you didn’t receive a tax benefit when you funded the account, you’ll get the money tax-free. And, unlike a 401(k) or a traditional IRA, you aren’t required to take required minimum distributions (RMDs) after a certain age.<br></br>And if for some reason you need the money in your Roth IRA before retirement, you can withdraw the contributions — but not investment earnings — at any time without additional taxes or penalties from the IRS.",
      dlg403B:
        "This is text about how UCRP works. It is a pension plan detailed here: https://ucnet.universityofcalifornia.edu/forms/pdf/retirement-handbook.pdf",
      dlg457B:
        "This is text about how UCRP works. It is a pension plan detailed here: https://ucnet.universityofcalifornia.edu/forms/pdf/retirement-handbook.pdf",
    };

    this.state.loremipsum = [...new Array(50)]
      .map(
        () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
      )
      .join("\n");
  }

  handleParamChange = (name, value) => {
    this.setState({ [name]: value });

    switch (name) {
      case "pensionIncl":
        this.props.Q2handler(
          value,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "EmploymentStartDate":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          value
        );
        break;
      case "SSIIncl":
        this.props.Q2handler(
          this.state.pensionIncl,
          value,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "rothIncl":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          value,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "rothBal":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          value,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "rothContributions":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          value,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "r403BIncl":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          value,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "r403BBal":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          value,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "r403BContributions":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          value,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "r457BIncl":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          value,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "r457BBal":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          value,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
      case "r457BContributions":
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          value,
          this.state.EmploymentStartDate
        );
        break;
      default:
        this.props.Q2handler(
          this.state.pensionIncl,
          this.state.SSIIncl,
          this.state.rothIncl,
          this.state.rothBal,
          this.state.rothContributions,
          this.state.r403BIncl,
          this.state.r403BBal,
          this.state.r403BContributions,
          this.state.r457BIncl,
          this.state.r457BBal,
          this.state.r457BContributions,
          this.state.EmploymentStartDate
        );
        break;
    }
  };

  handleCloseDlg = (event) => {
    this.setState({ dlgOpen: false });
  };

  handleOpenDlg = (scrollType, newTitle, newText) => () => {
    this.setState({
      dlgOpen: true,
      dlgScroll: scrollType,
      dlgText: newText,
      dlgTitle: newTitle,
    });
  };

  render() {
    let pensionStart, rothAmounts, r403BAmounts, r457BAmounts;

    if (this.state.pensionIncl) {
      pensionStart = (
        <tr>
          <td></td>
          <td>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id="DatePicker"
                label={"Employment Start Year"}
                views={["year"]}
                dateFormat="yyyy"
                onChange={(date) =>
                  this.handleParamChange("EmploymentStartDate", date)
                }
                selected={this.state.EmploymentStartDate}
                defaultValue={this.state.EmploymentStartDate}
                minDate={dayjs("1/1/1968")}
                maxDate={dayjs("1/1/2021")}
              />
            </LocalizationProvider>
          </td>
        </tr>
      );
    } else {
      pensionStart = "";
    }

    if (this.state.rothIncl) {
      rothAmounts = (
        <tr>
          <td></td>
          <td>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Current Val
              </InputLabel>
              <Input
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                autoFocus
                onChange={(event) =>
                  this.handleParamChange(
                    "rothBal",
                    parseInt(event.target.value)
                  )
                }
                value={this.state.rothBal}
                style={{ width: 95 }}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Annual Contribution
              </InputLabel>
              <Input
                id="outlined-adornment-amount2"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                autoFocus
                margin="none"
                type="string"
                onChange={(event) =>
                  this.handleParamChange(
                    "rothContributions",
                    event.target.value
                  )
                }
                value={this.state.rothContributions}
                style={{ width: 95 }}
              />
            </FormControl>
          </td>
        </tr>
      );
    } else {
      rothAmounts = "";
    }

    if (this.state.r403BIncl) {
      r403BAmounts = (
        <tr>
          <td></td>
          <td>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Current Val
              </InputLabel>
              <Input
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                autoFocus
                onChange={(event) =>
                  this.handleParamChange(
                    "r403BBal",
                    parseInt(event.target.value)
                  )
                }
                value={this.state.r403BBal}
                style={{ width: 95 }}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Annual Contribution
              </InputLabel>
              <Input
                id="outlined-adornment-amount2"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                autoFocus
                margin="none"
                type="string"
                onChange={(event) =>
                  this.handleParamChange(
                    "r403BContributions",
                    event.target.value
                  )
                }
                value={this.state.r403BContributions}
                style={{ width: 95 }}
              />
            </FormControl>
          </td>
        </tr>
      );
    } else {
      r403BAmounts = "";
    }

    if (this.state.r457BIncl) {
      r457BAmounts = (
        <tr>
          <td></td>
          <td>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Current Val
              </InputLabel>
              <Input
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                autoFocus
                onChange={(event) =>
                  this.handleParamChange(
                    "r457BBal",
                    parseInt(event.target.value)
                  )
                }
                value={this.state.r457BBal}
                style={{ width: 95 }}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Annual Contribution
              </InputLabel>
              <Input
                id="outlined-adornment-amount2"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                autoFocus
                margin="none"
                type="string"
                onChange={(event) =>
                  this.handleParamChange(
                    "r457BContributions",
                    event.target.value
                  )
                }
                value={this.state.r457BContributions}
                style={{ width: 95 }}
              />
            </FormControl>
          </td>
        </tr>
      );
    } else {
      r457BAmounts = "";
    }

    return (
      <div
        style={{
          textAlign: "left",
        }}
      >
        <div>
          <Accordion expanded={true}>
            <AccordionDetails>
              <h3>Retirement Sources</h3>
              <table>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>

              <table>
                {/* <tr>
                <th>Company</th>
                <th>Contact</th>
                <th>Country</th>
              </tr> */}
                <tr>
                  <td>
                    <Checkbox
                      defaultChecked={this.state.SSIIncl}
                      label=""
                      value={this.state.SSIIncl}
                      onChange={(event) =>
                        this.handleParamChange("SSIIncl", event.target.checked)
                      }
                    />
                  </td>
                  <td>
                    <b>Social Security</b> 🇺🇸
                  </td>
                  <td> </td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      defaultChecked={this.state.pensionIncl}
                      label=""
                      value={this.state.pensionIncl}
                      onChange={(event) =>
                        this.handleParamChange(
                          "pensionIncl",
                          event.target.checked
                        )
                      }
                    />
                  </td>
                  <td>
                    <b>
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={this.handleOpenDlg(
                          "paper",
                          "UCRP",
                          this.state.dlgUCRP
                        )}
                      >
                        UCRP
                      </Link>
                    </b>{" "}
                    (pension) 👴
                  </td>
                  <td> </td>
                </tr>
                {pensionStart}
                <tr>
                  <td>
                    <Checkbox
                      defaultChecked={this.state.rothIncl}
                      label=""
                      value={this.state.rothIncl}
                      onChange={(event) =>
                        this.handleParamChange("rothIncl", event.target.checked)
                      }
                    />
                  </td>
                  <td>
                    <b>
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={this.handleOpenDlg(
                          "paper",
                          "Roth IRA",
                          this.state.dlgRoth
                        )}
                      >
                        Roth IRA
                      </Link>{" "}
                      📈
                    </b>
                  </td>
                </tr>
                {rothAmounts}
                <tr>
                  <td>
                    <Checkbox
                      defaultChecked={this.state.r403BIncl}
                      label=""
                      value={this.state.r403BIncl}
                      onChange={(event) =>
                        this.handleParamChange(
                          "r403BIncl",
                          event.target.checked
                        )
                      }
                    />
                  </td>
                  <td>
                    <b>401k/403B</b> 📈
                  </td>
                </tr>
                {r403BAmounts}
                <tr>
                  <td>
                    <Checkbox
                      defaultChecked={this.state.r457BIncl}
                      label=""
                      value={this.state.r457BIncl}
                      onChange={(event) =>
                        this.handleParamChange(
                          "r457BIncl",
                          event.target.checked
                        )
                      }
                    />
                  </td>
                  <td>
                    <b>457B</b> 📈
                  </td>
                </tr>
                {r457BAmounts}
              </table>
            </AccordionDetails>
          </Accordion>
        </div>

        <br></br>

        <div>
          <Dialog
            open={this.state.dlgOpen}
            onClose={this.handleCloseDlg}
            scroll={this.state.dlgScroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              {this.state.dlgTitle}
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="scroll-dialog-description"
                //ref={descriptionElementRef}
                tabIndex={-1}
              >
                {this.state.dlgText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDlg}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default QuestionSet2;
