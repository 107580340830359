import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Account Health",
    },
  },
  scales: {
    y: {
      type: "linear",
      min: 0,
    },
  },
};

export default function AcctLineChart(props) {
  //let labels = {}
  let data = [];

  if (props.data.length !== 0) {
    let jsonData = props.data;

    let labels = jsonData.map(function (record) {
      return record.year;
    });
    let rothArray = jsonData.map(function (record) {
      return record.rothBal;
    });
    let r403BArray = jsonData.map(function (record) {
      return record.r403BBal;
    });
    let r457BArray = jsonData.map(function (record) {
      return record.r457BBal;
    });

    if (!props.rothIncl) {
      rothArray = null;
    }
    if (!props.r403BIncl) {
      r403BArray = null;
    }
    if (!props.r457BIncl) {
      r457BArray = null;
    }

    data = {
      labels,
      datasets: [
        {
          label: "Roth",
          data: rothArray,
          borderColor: "rgb(51, 102, 205)",
          backgroundColor: "rgb(51, 102, 205)",
        },
        {
          label: "403B",
          data: r403BArray,
          borderColor: "rgb(10, 19, 170)",
          backgroundColor: "rgb(10, 19, 170)",
        },
        {
          label: "457B",
          data: r457BArray,
          borderColor: "rgb(153, 204, 255)",
          backgroundColor: "rgb(153, 204, 255)",
        },
      ],
    };
  }

  return <Line options={options} data={data} />;
}
