import './App.css';

import TabView from  "./TabView";

function App() {
  return (
    <div className="App">

      <TabView/>

    </div>
  );
}

export default App;
