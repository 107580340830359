import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Future Income Projection",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export default function StackedBar(props) {
  let data = [];

  if (props.data.length !== 0) {
    let jsonData = props.data;

    let labels = jsonData.map(function (record) {
      return record.year;
    });
    let salaryArray = jsonData.map(function (record) {
      return record.sal;
    });

    data = {
      labels,
      datasets: [
        {
          label: "Salary",
          data: salaryArray,
          backgroundColor: "rgb(117, 117, 117)",
        },
      ],
    };

    if (props.SSIIncl) {
      let ssiArray = jsonData.map(function (record) {
        return record.SSI;
      });
      data.datasets.push({
        label: "SSI",
        data: ssiArray,
        backgroundColor: "rgb(64, 134, 60)",
      });
    }

    if (props.pensionIncl) {
      let ucrpArray = jsonData.map(function (record) {
        return record.ucrp;
      });
      data.datasets.push({
        label: "UCRP",
        data: ucrpArray,
        backgroundColor: "rgb(4, 74, 0)",
      });
    }

    if (props.rothIncl) {
      let rothArray = jsonData.map(function (record) {
        return record.rothExp;
      });
      data.datasets.push({
        label: "Roth",
        data: rothArray,
        backgroundColor: "rgb(51, 102, 205)",
      });
    }

    if (props.r403BIncl) {
      let r403BArray = jsonData.map(function (record) {
        return record.r403BExp;
      });
      data.datasets.push({
        label: "403B",
        data: r403BArray,
        backgroundColor: "rgb(10, 19, 170)",
      });
    }

    if (props.r457BIncl) {
      let r457BArray = jsonData.map(function (record) {
        return record.r457BExp;
      });
      data.datasets.push({
        label: "457B",
        data: r457BArray,
        backgroundColor: "rgb(153, 204, 255)",
      });
    }
  }
  return <Bar options={options} data={data} />;
}
