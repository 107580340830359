import React from "react";

import Slider from "@mui/material/Slider";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import countDefault from "../../src/assets/dc_fast.gif";
import countMed from "../../src/assets/dc.gif";
import countSlow from "../../src/assets/dc_slow.gif";

import dispense from "../../src/assets/dispense.gif";
import dispense2 from "../../src/assets/dispense2.gif";
import dispense3 from "../../src/assets/dispense3.gif";

import stonks from "../../src/assets/stonks.gif";

import finedog from "../../src/assets/this_is_fine.png";

class QuestionSet3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ExpectedSalaryGrowth: this.props.ExpectedSalaryGrowth,
      PreRetirementStockGrowth: this.props.PreRetirementStockGrowth,
      PostRetirementStockGrowth: this.props.PostRetirementStockGrowth,
      ExpectedUCRPCOLA: this.props.ExpectedUCRPCOLA,
      ExpectedIRAYearlyAdjustments: this.props.ExpectedIRAYearlyAdjustments,
      
      ExpectedSalaryGrowthGif: countMed,
      PreRetirementStockGrowthGif: stonks,
      PostRetirementStockGrowthGif: finedog,
      ExpectedUCRPCOLAGif: dispense,
    };
  }

  handleParamChange = (name, value) => {
    this.setState({ [name]: value });

    switch (name) {
      case "ExpectedSalaryGrowth":
        this.props.Q3handler(
          value,
          this.state.PreRetirementStockGrowth,
          this.state.PostRetirementStockGrowth,
          this.state.ExpectedUCRPCOLA,
          this.state.ExpectedIRAYearlyAdjustments
        );
        if (value <= 0.04) {
          this.setState({ ExpectedSalaryGrowthGif: countSlow });
        } else if (value >= 0.04 && value <= 0.07) {
          this.setState({ ExpectedSalaryGrowthGif: countMed });
        } else {
          this.setState({ ExpectedSalaryGrowthGif: countDefault });
        }
        break;
      case "PreRetirementStockGrowth":
        this.props.Q3handler(
          this.state.ExpectedSalaryGrowth,
          value,
          this.state.PostRetirementStockGrowth,
          this.state.ExpectedUCRPCOLA,
          this.state.ExpectedIRAYearlyAdjustments
        );
        break;
      case "PostRetirementStockGrowth":
        this.props.Q3handler(
          this.state.ExpectedSalaryGrowth,
          this.state.PreRetirementStockGrowth,
          value,
          this.state.ExpectedUCRPCOLA,
          this.state.ExpectedIRAYearlyAdjustments
        );
        break;
      case "ExpectedUCRPCOLA":
        this.props.Q3handler(
          this.state.ExpectedSalaryGrowth,
          this.state.PreRetirementStockGrowth,
          this.state.PostRetirementStockGrowth,
          value,
          this.state.ExpectedIRAYearlyAdjustments
        );
        if (value <= 0.04) {
          this.setState({ ExpectedUCRPCOLAGif: dispense });
        } else if (value >= 0.04 && value <= 0.07) {
          this.setState({ ExpectedUCRPCOLAGif: dispense2 });
        } else {
          this.setState({ ExpectedUCRPCOLAGif: dispense3 });
        }
        break;
      case "ExpectedIRAYearlyAdjustments":
        this.props.Q3handler(
          this.state.ExpectedSalaryGrowth,
          this.state.PreRetirementStockGrowth,
          this.state.PostRetirementStockGrowth,
          this.state.ExpectedUCRPCOLA,
          value
        );
        break;
      default:
        this.props.Q3handler(
          this.state.ExpectedSalaryGrowth,
          this.state.PreRetirementStockGrowth,
          this.state.PostRetirementStockGrowth,
          this.state.ExpectedUCRPCOLA,
          this.state.ExpectedIRAYearlyAdjustments
        );
        break;
    }
  };

  render() {
    return (
      <div
        style={{
          textAlign: "left",
        }}
      >
        <div>
          <Accordion expanded={true}>
            <AccordionDetails>
              <h3>Optimism/Realism</h3>
              <br />

              <Container maxWidth="md">
                <Grid container spacing={4}>
                  <Grid item key={1} xs={12} sm={6} md={4}>
                    <Card>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={this.state.ExpectedSalaryGrowthGif}
                        title="Image title"
                      />
                      <CardContent>
                        <Typography>
                          {"How much will your salary grow annually?"}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Slider
                          //defaultValue={0.03}
                          valueLabelDisplay="on"
                          orientation="horizontal"
                          step={0.01}
                          //marks
                          valueLabelFormat={valueLabelFormat}
                          min={0}
                          max={0.1}
                          style={{
                            width: 200,
                            color: RiskColor(this.state.ExpectedSalaryGrowth),
                          }}
                          value={this.state.ExpectedSalaryGrowth}
                          onChange={(event) =>
                            this.handleParamChange(
                              "ExpectedSalaryGrowth",
                              event.target.value
                            )
                          }
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item key={2} xs={12} sm={6} md={4}>
                    <Card>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={this.state.PreRetirementStockGrowthGif}
                        title="Image title"
                      />
                      <CardContent>
                        <Typography>
                          {"How will your investments perform? (APY)"}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Slider
                          defaultValue={0.03}
                          valueLabelDisplay="on"
                          orientation="horizontal"
                          step={0.01}
                          //marks
                          valueLabelFormat={valueLabelFormat}
                          min={0}
                          max={0.1}
                          style={{
                            width: 200,
                            color: RiskColor(
                              this.state.PreRetirementStockGrowth
                            ),
                          }}
                          value={this.state.PreRetirementStockGrowth}
                          onChange={(event) =>
                            this.handleParamChange(
                              "PreRetirementStockGrowth",
                              event.target.value
                            )
                          }
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item key={3} xs={12} sm={6} md={4}>
                    <Card>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={this.state.PostRetirementStockGrowthGif}
                        title="Image title"
                      />
                      <CardContent>
                        <Typography>
                          {"How risky do you want to invest once retired?"}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Slider
                          defaultValue={0.03}
                          valueLabelDisplay="on"
                          orientation="horizontal"
                          step={0.01}
                          //marks
                          valueLabelFormat={valueLabelFormat}
                          min={0}
                          max={0.1}
                          style={{
                            width: 200,
                            color: RiskColor(
                              this.state.PostRetirementStockGrowth
                            ),
                          }}
                          value={this.state.PostRetirementStockGrowth}
                          onChange={(event) =>
                            this.handleParamChange(
                              "PostRetirementStockGrowth",
                              event.target.value
                            )
                          }
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item key={4} xs={12} sm={6} md={4}>
                    <Card>
                      <CardMedia
                        sx={{ height: 140 }}
                        image={this.state.ExpectedUCRPCOLAGif}
                        title="Image title"
                      />
                      <CardContent>
                        <Typography>
                          {
                            "How generous will your cost of living adjustments be in your pension plan?"
                          }
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Slider
                          defaultValue={0.03}
                          valueLabelDisplay="on"
                          orientation="horizontal"
                          step={0.01}
                          //marks
                          valueLabelFormat={valueLabelFormat}
                          min={0}
                          max={0.1}
                          style={{
                            width: 200,
                            color: RiskColor(this.state.ExpectedUCRPCOLA),
                          }}
                          value={this.state.ExpectedUCRPCOLA}
                          onChange={(event) =>
                            this.handleParamChange(
                              "ExpectedUCRPCOLA",
                              event.target.value
                            )
                          }
                        />
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
              <br />
            </AccordionDetails>
          </Accordion>
        </div>

        <br></br>
      </div>
    );
  }
}

export default QuestionSet3;

function RiskColor(val) {
  if (val <= 0.04) {
    return "darkgreen";
  } else if (val >= 0.04 && val <= 0.07) {
    return "olive";
  } else {
    return "maroon";
  }
}

function valueLabelFormat(value) {
  const units = "%";
  var v = Math.trunc(value * 100);
  return `${units} ${v}`;
}
